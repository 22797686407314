import {
  FaEnvelope,
  FaEnvelopeOpen,
  FaGlobeAsia,
  FaPhone,
} from "react-icons/fa";
import logo from "../../assets/images/Logo.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div
      style={{
        backgroundColor: "#2E282A",
        height: "50vh",
        color: "white",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "3rem",
          justifyContent: "center",
        }}
      >
        <img alt="logo" src={logo} height={50} width={50} />
        <h4>Apsaral Tech Solutions Pvt Ltd</h4>
        <p>
          No:33a,Pillaiyar Kovil Street ,
          <br />
          Chinna Melamaiyur,Chengalpattu ,
          <br />
          Tamilnadu ,603001
          <p>© {new Date().getFullYear()} Apsaral. All rights reserved.</p>
        </p>
      </div>
      <div
        style={{
          marginTop: "5rem",
        }}
      >
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <li>
            <Link style={{ textDecoration: "none", color: "white" }} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/technology"
            >
              Services
            </Link>
          </li>
          <li>
            <Link style={{ textDecoration: "none", color: "white" }} to="/">
              About us
            </Link>
          </li>
          <li>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to="/contact"
            >
              Contact us
            </Link>
          </li>
        </ul>
      </div>
      <div
        style={{
          marginTop: "5rem",
        }}
      >
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <li>
            <FaGlobeAsia /> Website: www.apsaral.com
          </li>
          <li>
            {" "}
            <FaPhone /> Contact: +91 44 3570 4381
          </li>
          <li>
            <FaEnvelopeOpen />
            {""}
            Email: contact@apsaral.com
          </li>
        </ul>
      </div>
      <div>
        <iframe
          title="apsaral"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3892.445219304658!2d79.97998477429843!3d12.68433812111281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52fc41c0675563%3A0x3fc8d9abd75451b9!2sPillaiyar%20Kovil%20St%2C%20Gokulapuram%2C%20Chengalpattu%2C%20Tamil%20Nadu%20603001!5e0!3m2!1sen!2sin!4v1721381003373!5m2!1sen!2sin"
          width="500"
          height="350px"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

export default Footer;
