import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";

import Scrapping from "./pages/Scrapping/Scrapping";
import Watermark from "./pages/Watermark/Watermark";
import ImageSearch from "./pages/ImageSearch/ImageSearch";
import Technology from "./pages/Technology/Technology";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TraineeProgram from "./pages/TraineeProgram/TraineeProgram";
import ContactUs from "./pages/ContactUs1/ContactUs";
import Layout from "./components/Layout";
import About from "./pages/About/About";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <div>
                <Hero />
                <Scrapping />
                <ImageSearch />
                <Watermark />
                <Technology />
              </div>
            }
          />
          <Route
            path="/technology"
            element={
              <div>
                <Scrapping />
                <ImageSearch />
                <Watermark />
              </div>
            }
          />
          <Route path="/traineeProgram" element={<TraineeProgram />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<div>404</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
