import grid from "../../assets/styles/grid.module.css";
import common from "../../assets/styles/common.module.css";
import "./contact.css";
function ContactUs() {
  return (
    <div className={`${grid.grid} ${grid.grid__col_2}  ${common.vh_00}`}>
      <div
        style={{
          padding: "0 3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "red",
          color: "white",
        }}
      >
        <div>
          <h2 style={{ fontSize: "3rem" }}>Contact Us</h2>

          <p>
            Ready to take the next step? Get in touch with us to discuss your
            needs and discover how we can assist you in reaching your goals.
          </p>
          <div>
            <ul>
              <li>
                Email:
                <a style={{ color: "white" }} href="mailto:contact@apsaral.com">
                  contact@apsaral.com
                </a>
              </li>
              <li> Contact:+91 44 3570 4381</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingLeft: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
        }}
      >
        <div className="form-container">
          <form>
            <div className="grid-container">
              <div className="form-group ">
                <label htmlFor="firstName"> Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  // onChange={handleChange}
                />
              </div>
              <div className="form-group ">
                <label htmlFor="firstName">Email</label>
                <input
                  type="email"
                  id="firstName"
                  name="firstName"
                  // onChange={handleChange}
                />
              </div>
              <div className="form-group full-width">
                <label htmlFor="firstName">Subject</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  // onChange={handleChange}
                />
              </div>
              <div className="form-group full-width">
                <label htmlFor="firstName">Message</label>
                <textarea
                  type="text"
                  id="firstName"
                  name="firstName"
                  // onChange={handleChange}
                />
              </div>
              <div className="form-actions">
                <button
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => console.log("save")}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
