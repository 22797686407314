import styles from "./Hero.module.css";
import grid from "../../assets/styles/grid.module.css";
import common from "../../assets/styles/common.module.css";
// import hero from "../../assets/images/hero_bg_2.jpg";
import heroBgRight from "../../assets/images/hero_bg_right.svg";
function Hero() {
  return (
    <div
      className={`${styles.hero__wrapper} ${common.vh_100}`}
      style={{ overflow: "hidden", position: "relative" }}
    >
      {/* <img
        src={hero}
        alt="hero_bg"
        style={{ width: "100%", objectFit: "cover" }}
      /> */}
      <div
        className={styles.grid_content}
        style={{ position: "absolute", top: 0, width: "100%", height: "100%" }}
      >
        <div>
          <div className={styles.hero__wrapper_left}>
            <div
              className={`${styles.hero__text}`}
              style={{ fontSize: "80px", fontWeight: "600" }}
            >
              Empowering <br /> Innovation and Efficiency
              <p
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "normal",
                  color: "#333",
                }}
              >
                Scraping Solutions with Selenium | MVP Inventory Search Engine |
                Pioneering Watermark Removal Transforming Traczan Operations |
                Seamless Client-Centric Web and Mobile Apps
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={heroBgRight} alt="hero_right_bg" />
        </div>
      </div>
    </div>
  );
}

export default Hero;
