import { Link } from "react-router-dom";
import logo from "../../assets/images/apsaral_logo_full.png";
import styles from "./Header.module.css";
import "./Header.module.css";
function Header() {
  return (
    <header className={styles.header}>
      <div className={styles.header__logo_wrapper}>
        <a href="/">
          <img
            className={styles.header__logo}
            src={logo}
            alt="apsaral_logo_full"
          />
        </a>
      </div>
      <div className={styles.header__menu_wrapper}>
        <ul className={styles.header__menu}>
          <li className={styles.header__menu_item}>
            <a href="/">Home</a>
          </li>
          <li className={styles.header__menu_item}>
            <Link to="/technology">Services</Link>
          </li>
          <li className={styles.header__menu_item}>
            <div class={styles.dropdown}>
              <button class={styles.dropbtn}>Career</button>
              <div class={styles.dropdown_content}>
                <Link to={"/traineeProgram"}>Trainee Program</Link>
              </div>
            </div>
          </li>
          <li className={styles.header__menu_item}>
            <Link to="/about">About us</Link>
          </li>
          <li className={styles.header__menu_item}>
            <Link to={"/contact"}>Contact us</Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
