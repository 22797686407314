import grid from "../../assets/styles/grid.module.css";
import common from "../../assets/styles/common.module.css";
import webScrappingBg from "../../assets/images/web_scrapping_bg.svg";
function About() {
  return (
    <div className={`${grid.grid} ${grid.grid__col_2}  ${common.vh_100}`}>
      <div
        style={{
          padding: "0 3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "red",
          color: "white",
        }}
      >
        <div>
          <h2 style={{ fontSize: "3rem" }}>About Us</h2>
          <h3>About Us</h3>
          <p>
            Welcome to Apsaral Tech, where innovation meets excellence. At
            Apsaral Tech, we’re dedicated to transforming the digital landscape
            with cutting-edge technology solutions that empower businesses and
            drive growth. Founded on a passion for technology and a commitment
            to quality, Apsaral Tech is a forward-thinking IT startup
            specializing in web scraping, data extraction, software
            development,Watermark Removal etc.. Our mission is to deliver
            high-performance solutions tailored to meet the unique needs of each
            client, helping them navigate the ever-evolving tech world with
            confidence and ease.
          </p>
          <h3>Our Vision</h3>
          <p>
            Our vision is to be a catalyst for technological advancement,
            pushing the boundaries of what’s possible to create innovative
            solutions that lead the industry. We aim to set new standards in IT
            excellence, driven by a dedication to creativity, collaboration, and
            customer success.
          </p>
          <h3>Our Approach</h3>
          <p>
            At Apsaral Tech, we believe in a client-centric approach. We start
            by understanding your goals, challenges, and aspirations, then
            leverage our expertise and advanced technologies to deliver
            solutions that not only meet but exceed expectations. Our team of
            skilled professionals brings a wealth of experience and a diverse
            set of skills to every project, ensuring that we provide
            comprehensive and effective solutions.
          </p>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
        }}
      >
        <img src={webScrappingBg} alt="web_scrapping_bg" />
      </div>
    </div>
  );
}

export default About;
