import grid from "../../assets/styles/grid.module.css";
import common from "../../assets/styles/common.module.css";
import webScrappingBg from "../../assets/images/image_optimized1.svg";
function ImageSearch() {
  return (
    <div className={`${grid.grid} ${grid.grid__col_2}  ${common.vh_100}`}>
      <div
        style={{
          padding: "0 3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "red",
          color: "white",
        }}
      >
        <div>
          <h2 style={{ fontSize: "3rem" }}>Reverse Image Search Engine</h2>
          <h3>Your Reliable Tool for Discovering Image Origins</h3>
          <p>
            Reverse Image Search Engine is your go-to platform for conducting
            reverse image searches effortlessly. Whether you're a professional
            researcher, a content creator, or simply curious about where an
            image originated, our intuitive tool provides accurate results in
            seconds.
          </p>
          <h3>Finding Similar Images</h3>
          <p>
            <b>Fast and Efficient:</b> Instantly find the source or similar
            images with our efficient search algorithm
          </p>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
        }}
      >
        <img src={webScrappingBg} alt="web_scrapping_bg" width={"100%"}/>
      </div>
    </div>
  );
}

export default ImageSearch;
