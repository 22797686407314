import grid from "../../assets/styles/grid.module.css";
import common from "../../assets/styles/common.module.css";
import webScrappingBg from "../../assets/images/web_scrapping_bg.svg";
function Scrapping() {
  return (
    <div className={`${grid.grid} ${grid.grid__col_2}  ${common.vh_100}`}>
      <div
        style={{
          paddingLeft: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
        }}
      >
        <img src={webScrappingBg} alt="web_scrapping_bg" />
      </div>
      <div
        style={{
          padding: "0 3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "red",
          color: "white",
        }}
      >
        <div>
          <h2 style={{ fontSize: "3rem" }}>Scraping Solutions</h2>
          <h3>Harnessing Selenium for Dynamic Data Extraction</h3>
          <p>
            Unlock the power of Selenium in C# and Python to navigate and
            extract data from dynamic web pages with precision and efficiency.
            Whether you need automated data scraping for competitive
            intelligence, market research, or business analytics, our team
            leverages Selenium's robust capabilities to deliver accurate and
            timely results.
          </p>
          <h3>Traditional Web Scraping Expertise</h3>
          <p>
            In addition to Selenium, we excel in traditional web scraping
            techniques tailored to your specific requirements. From structured
            data extraction to custom scraping scripts, our expertise spans
            across various domains and industries.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Scrapping;
