import React from "react";
import python from "../../assets/technology img/python.png";
import c from "../../assets/technology img/c-sharp-logo.png";
import react from "../../assets/technology img/react-native.png";
import java from "../../assets/technology img/java.png";
import js from "../../assets/technology img/javascript.png";
import css from "../../assets/technology img/css.png";
import html from "../../assets/technology img/html.png";
import msSql from "../../assets/technology img/sql-server.png";
import mySql from "../../assets/technology img/mysql-logo.png";
import mongodb from "../../assets/technology img/mongodb.png";
import api from "../../assets/technology img/api.png";
import mService from "../../assets/technology img/micro_service1.jpg";

const Technology = () => {
  return (
    <div
      style={{
        height: "70vh",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          height: "150px",
          width: "40%",
          borderRadius: "20px",
          boxShadow: "0px 10px 30px 0px #d7d7d7",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={python} />
          <span>Python</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={c} style={{ display: "block" }} />
          <span>C#</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={java} style={{ display: "block" }} />
          <span>Java</span>
        </div>
      </div>
      <div
        style={{
          height: "150px",
          width: "40%",
          borderRadius: "20px",
          boxShadow: "0px 10px 30px 0px #d7d7d7",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={html} />
          <span>HTML</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={css} style={{ display: "block" }} />
          <span>CSS</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={js} style={{ display: "block" }} />
          <span>Javascript</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={react} style={{ display: "block" }} />
          <span>React</span>
        </div>
      </div>
      <div
        style={{
          height: "150px",
          width: "40%",
          borderRadius: "20px",
          boxShadow: "0px 10px 30px 0px #d7d7d7",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={mongodb} />
          <span>MongoDB</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={msSql} style={{ display: "block" }} />
          <span>MsSQL</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={mySql} style={{ display: "block" }} />
          <span>MySQL</span>
        </div>
      </div>
      <div
        style={{
          height: "150px",
          width: "40%",
          borderRadius: "20px",
          boxShadow: "0px 10px 30px 0px #d7d7d7",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="tech" src={api} style={{ display: "block" }} />
          <span>RestFul APIs</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            alt="tech"
            height={48}
            src={mService}
            style={{ display: "block" }}
          />
          <span>Microservices</span>
        </div>
      </div>
    </div>
  );
};

export default Technology;
