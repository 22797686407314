import grid from "../../assets/styles/grid.module.css";
import common from "../../assets/styles/common.module.css";
import webScrappingBg from "../../assets/images/Watermark.svg";
function Watermark() {
  return (
    <div className={`${grid.grid} ${grid.grid__col_2}  ${common.vh_100}`}>
      <div
        style={{
          paddingLeft: "3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f7f7f7",
        }}
      >
        <img src={webScrappingBg} alt="web_scrapping_bg" />
      </div>
      <div
        style={{
          padding: "0 3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "red",
          color: "white",
        }}
      >
        <div>
          <h2 style={{ fontSize: "3rem" }}>Water Mark Removal</h2>
          <h3>Your Trusted Partner in Removing Watermarks from Images</h3>
          <p>
            Pioneering Watermark Removal is dedicated to delivering high-quality
            image editing services with a focus on removing watermarks
            efficiently and effectively. With years of experience and a team of
            skilled professionals, we guarantee superior results that meet your
            expectations.
          </p>
          <h3>Remove Watermarks Professionally</h3>
          <p>
            Watermark Removal: We specialize in removing watermarks from all
            types of images, ensuring a clean and professional look.
            <br />
            Image Restoration: Beyond watermark removal, we also offer image
            restoration services to enhance and repair damaged or aged
            photographs.
            <br />
            Bulk Processing: Need to process a large volume of images? Our bulk
            processing service ensures quick turnaround times without
            compromising on quality.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Watermark;
