import React from 'react'
import img from '../../assets/images/Traine Program.png'

const TraineeProgram = () => {
  return (
    <div>
      <img style={{width:'100%', height:'100%'}} src={img} alt='trainee program'/>
    </div>
  )
}

export default TraineeProgram
